<template>
  <div :id="name" :ref="(el) => (refs[name].value = el as HTMLInputElement)" class="relative">
    <slot name="activator" :is-open="showMenu" :on-click="toggle">
      <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
      <div
        class="flex cursor-pointer flex-row items-center justify-between rounded-xl border-[1.5px] px-4 py-3 text-black-100"
        :class="[
          error ? 'border-error-100 ' : 'border-black-20',
          {
            '!cursor-default border-black-20 bg-black-05 text-black-60': disabled,
          },
          compact ? 'h-10' : 'h-12',
        ]"
        @click="toggle"
      >
        <small class="line-clamp-1 max-w-[calc(100%-1.75rem)]" :class="{ 'text-black-40': !selectedName }">
          {{ selectedName || inputPlaceholder }}
        </small>
        <UiIcon
          name="chevron-big-filled-down"
          class="text-black-60 transition-all duration-200"
          :class="{ 'rotate-180': showMenu }"
        />
      </div>
    </slot>
    <Teleport to="body">
      <transition name="fade">
        <div
          v-if="showMenu"
          :id="`${name}_menu`"
          :ref="(el) => (refs[`${name}_menu`].value = el as HTMLInputElement)"
          class="fixed z-50 rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-2 shadow"
          :class="[{ '!p-2': group }, menuClasses]"
        >
          <p v-if="title" class="text-subhead-3 mb-3 text-center uppercase">{{ title }}</p>
          <ol>
            <label
              v-if="search"
              class="relative flex w-full min-w-[100px] flex-row items-center justify-between gap-2 px-4 py-3"
              :class="[compact ? 'h-10' : 'h-12', searchClasses]"
              @input="input($event)"
              @focus="isInputFocused = true"
              @keydown.enter="keyDownEnterInput"
            >
              <UiIcon
                name="search"
                :class="[isInputFocused ? 'text-primary-100' : 'text-black-60']"
                class="peer/icon-prefix z-10"
              ></UiIcon>
              <input
                :id="name"
                :value="shallowValue"
                :name="name"
                type="text"
                autocomplete="off"
                placeholder="Search"
                class="peer z-10 h-[20px] w-[inherit] flex-1 border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5"
                @focus="isInputFocused = true"
              />
              <transition name="fade" mode="out-in">
                <UiIcon
                  v-if="shallowValue"
                  name="big-close"
                  class="peer/icon z-10 cursor-pointer text-black-60 transition-all duration-200 peer-focus-visible:text-primary-100"
                  @click="shallowValue = ''"
                />
              </transition>
              <div
                class="absolute left-0 z-0 size-full rounded-xl border-[1.5px] border-solid border-black-20 outline-none transition-colors duration-200 hover:border-primary-50 active:border-primary-100 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50 peer-focus:border-primary-100 peer-active:border-primary-100 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05"
              ></div>
            </label>

            <div class="styled-scrollbar-near max-h-[250px] overflow-y-auto" :class="{ 'p-5': extraPaddings }">
              <UiLoader v-if="loading" class="mt-2" />
              <div v-else>
                <UiInputCheckbox
                  v-if="multiple && withSelectAll"
                  v-model="allSelected"
                  class="w-full cursor-pointer border-b border-solid border-black-10 p-3 hover:bg-black-03 active:bg-primary-05"
                  :class="[compact ? 'h-10' : 'h-12', { 'text-primary-100': allSelected }]"
                  name="menu_select_all"
                  :compact
                  label="Select all"
                  :indeterminate="indeterminate"
                  @click.stop
                />
                <div
                  v-if="filteredItems.filter((i) => i.important).length"
                  class="border-b border-solid border-black-10"
                >
                  <div v-if="multiple">
                    <UiInputSelectMultipleItem
                      v-for="(item, index) in filteredItems"
                      :key="index"
                      :model-value="modelValue"
                      :shallow-value="shallowValue"
                      :item="item"
                      :compact
                      :avatar
                      :selectable-heading
                      :dependents="getHeadingDependents(item)"
                      :heading-classes="headingsClasses"
                      @input="multipleSelect"
                      @select-dependents="selectDependents"
                    />
                  </div>
                  <div v-else>
                    <UiInputSelectSimpleItem
                      v-for="(item, index) in filteredItems.filter((i) => i.important)"
                      :key="`${item.value}_${index}`"
                      :model-value="modelValue"
                      :shallow-value="modelValue"
                      :item="item"
                      :flag="flag"
                      :avatar
                      :divider="divider"
                      :heading-classes="headingsClasses"
                      @input="select"
                    />
                  </div>
                </div>
                <div v-if="multiple">
                  <UiInputSelectMultipleItem
                    v-for="(item, index) in filteredItems"
                    :key="index"
                    :model-value="modelValue"
                    :shallow-value="shallowValue"
                    :item="item"
                    :compact
                    :avatar
                    :selectable-heading
                    :dependents="getHeadingDependents(item)"
                    :heading-classes="headingsClasses"
                    @select-dependents="selectDependents"
                    @input="multipleSelect"
                  />
                </div>
                <div v-else>
                  <UiInputSelectSimpleItem
                    v-for="(item, index) in filteredItems.filter((i) => !i.important)"
                    :key="`${item.value}_${index}`"
                    :model-value="modelValue"
                    :shallow-value="modelValue"
                    :item="item"
                    :flag="flag"
                    :avatar
                    :divider="divider"
                    :heading-classes="headingsClasses"
                    :item-classes="itemsClasses"
                    @input="select"
                  />
                </div>
              </div>
            </div>
            <li
              v-if="!filteredItems.length"
              class="text-body-2 flex select-none flex-row items-center p-3 text-black-60"
              :class="[compact ? 'h-10' : 'h-12']"
            >
              No options
            </li>
            <slot name="custom"></slot>
          </ol>
        </div>
      </transition>
    </Teleport>
    <div class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import orderBy from 'lodash/orderBy'
import { onClickOutside } from '@vueuse/core'
import type { InputItem } from '@/types'

const emits = defineEmits(['update:modelValue', 'closed'])

type Props = {
  modelValue: any
  items: InputItem[]
  name: string
  placeholder?: string
  width?: number
  search?: boolean
  extraPaddings?: boolean
  sort?: boolean
  multiple?: boolean
  disabled?: boolean
  alignRight?: boolean
  closeAfterClick?: boolean
  label?: string
  inputName?: string
  inputPlaceholder?: string
  withSelectAll?: boolean // to add a "Select all" option
  flag?: boolean
  avatar?: boolean
  divider?: boolean
  searchClasses?: string
  menuClasses?: string
  title?: string
  error?: string
  headingsClasses?: string
  itemsClasses?: string
  compact?: boolean
  group?: boolean
  selectableHeading?: boolean
  loading?: boolean
  readOnly?: boolean
  unselectOnClick?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  sort: false,
  width: 0,
  closeAfterClick: true,
  label: '',
  inputName: 'text',
  inputPlaceholder: 'Choose an option',
  searchClasses: '',
  menuClasses: '',
  title: '',
  error: '',
  headingsClasses: '',
  itemsClasses: '',
})

const isInputFocused = ref<boolean>(false)
const shallowValue = ref<string | string[]>('')
const showMenu = ref<boolean>(false)

const selectedName = computed(() => {
  return props.items.find((i) => i.value === props.modelValue)?.[props.inputName]
})

const allSelected = computed({
  get() {
    return props.modelValue?.length === props.items.length
  },
  set(value) {
    if (value) {
      emits(
        'update:modelValue',
        props.items.map((i) => i.value)
      )
    } else {
      emits('update:modelValue', [])
    }
  },
})

const indeterminate = computed(() => Boolean(props.modelValue.length && props.modelValue.length !== props.items.length))

const filteredItems = computed(() => {
  // We check the type of shallow value because on multiple mode, if the user types if changes the type from array to string
  let items: any[] = []
  if (shallowValue.value) {
    items = props.items.filter((i) =>
      i.text
        .trim()
        .toLowerCase()
        .includes((shallowValue.value as string).trim().toLowerCase())
    )
    if (props.sort) {
      return orderBy(items, ['important', 'text'], ['desc', 'asc'])
    } else return items
  } else if (props.sort) {
    return orderBy(props.items, ['important', 'text'], ['desc', 'asc'])
  } else {
    return props.items
  }
})

const refs = {
  [props.name]: ref<HTMLInputElement>(),
  [`${props.name}_menu`]: ref<HTMLElement>(),
}

const close = () => {
  if (showMenu.value) showMenu.value = false
  if (isInputFocused.value) isInputFocused.value = false
  shallowValue.value = ''
  emits('closed')
}

const calculatePosition = () => {
  if (!showMenu.value) return
  const viewportOffset = refs[props.name].value?.getBoundingClientRect()

  if (!viewportOffset) return
  // Here we get the element position to the viewport
  const top = viewportOffset.top
  const left = viewportOffset.left

  //   Then we set the position of the menu to those coordinates (if theres space)
  //   We do this because we use Teleport to transport to the body of the page
  //   Because otherwise, the menu would be clipped by tables, forms, etc
  //   Source: Trust me
  setTimeout(() => {
    const menu = refs[`${props.name}_menu`].value

    if (menu) {
      const spaceToBottom = window.innerHeight - top
      if (spaceToBottom < menu.offsetHeight + Number(refs[props.name].value?.offsetHeight)) {
        menu.style.top = `${top - menu.offsetHeight}px`
      } else {
        menu.style.top = `${top + Number(refs[props.name].value?.offsetHeight)}px`
      }

      const spaceToRight = window.innerWidth - left

      const width = Number(props.width || refs[props.name].value?.offsetWidth)

      if (spaceToRight < width || props.alignRight) {
        menu.style.left = `${left - width + Number(refs[props.name].value?.offsetWidth)}px`
      } else {
        menu.style.left = `${left}px`
      }

      menu.style.width = `${width}px`
    }
  })
}

const toggle = () => {
  if (props.disabled || props.readOnly) return
  showMenu.value = !showMenu.value
  calculatePosition()
}

const input = (event: Event) => {
  isInputFocused.value = true
  shallowValue.value = (event.target as HTMLInputElement).value
}

const keyDownEnterInput = () => {
  // If the focused element is the input himself, then the first item on the list in the one the user wants
  // Otherwise, the focused element is the item itself
  if (
    (document.activeElement as HTMLInputElement).nodeName === 'INPUT' &&
    filteredItems.value[0] &&
    !filteredItems.value[0].disabled &&
    (props.multiple ? shallowValue.value.length : shallowValue.value)
  ) {
    select(filteredItems.value[0].value)
  }
}

const select = (value: string) => {
  if (props.closeAfterClick) showMenu.value = false
  isInputFocused.value = false

  if (props.multiple) {
    let newArray = []
    // Remove or add depending if the value is in the array
    if (props.modelValue.includes(value)) {
      newArray = (props.modelValue as string[]).filter((v: string) => v !== value)
    } else {
      newArray = Array.from(new Set([...props.modelValue, value]))
    }

    emits('update:modelValue', newArray)
  } else {
    if (props.unselectOnClick && props.modelValue === value) {
      emits('update:modelValue', '')
    } else {
      emits('update:modelValue', value)
    }
    shallowValue.value = ''
  }
}

const multipleSelect = (value: string) => {
  let newArray = []
  // Remove or add depending if the value is in the array
  if (props.modelValue.includes(value)) {
    newArray = (props.modelValue as string[]).filter((v: string) => v !== value)
  } else {
    newArray = Array.from(new Set([...props.modelValue, value]))
  }

  emits('update:modelValue', newArray)
}

const HEADER_VALUE = 'heading'

// Get all items between two headings
const getHeadingDependents = (item: InputItem) => {
  if (item.value !== HEADER_VALUE) return []

  const currentIndex = props.items.findIndex((i) => i.value === item.value && i.text === item.text)
  let nextHeadingIndex = filteredItems.value.findIndex(
    (item, index) => item.value === HEADER_VALUE && index > currentIndex
  )
  if (nextHeadingIndex === -1) {
    nextHeadingIndex = filteredItems.value.length
  }

  return filteredItems.value.slice(currentIndex + 1, nextHeadingIndex)
}

// Select all dependents of a heading
const selectDependents = (dependents: InputItem[]) => {
  const dependentsValues = dependents.map((i) => i.value)
  // remove all or add all
  if (dependentsValues.every((i) => props.modelValue.includes(i))) {
    emits(
      'update:modelValue',
      props.modelValue.filter((i) => !dependentsValues.includes(i))
    )
  } else {
    emits('update:modelValue', Array.from(new Set([...props.modelValue, ...dependentsValues])))
  }
}

onMounted(() => {
  const menu = refs[`${props.name}_menu`]
  onClickOutside(menu, () => {
    close()
  })
  window.addEventListener('scroll', calculatePosition, true)
})

onUnmounted(() => window.removeEventListener('scroll', calculatePosition))

defineSlots<{
  activator(props: { isOpen: boolean; onClick: typeof toggle }): void
  custom(): void
}>()

defineExpose({
  close,
})
</script>

<style scoped></style>
