import { stringify } from 'qs'
import { useLibrariesStore } from '@/store/libraries'
import type { ApiResponse, PaginationResponse, Pipeline } from '@/types'

export const useGetPipelineById = async (id: number): Promise<Pipeline> => {
  const { data, error } = await useAPIFetch(`/pipelines/${id}`, {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  return toRaw((data.value as any)?.pipeline)
}

export const useGetPipelineByCode = async (code: string): Promise<Pipeline> => {
  const librariesStore = useLibrariesStore()
  const cachedPipeline = librariesStore.pipelines.find((p) => p.code === code)
  if (cachedPipeline) {
    return cachedPipeline
  }
  const { data, error } = await useAPIFetch(`/pipelines/code/${code}`, {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  const pipeline = toRaw((data.value as any)?.pipeline)
  librariesStore.addPipeline(pipeline)
  return pipeline
}

export const useGetPipelineLeads = async (id: number, query: any) => {
  const queryFormatted = stringify(query, { arrayFormat: 'comma', encode: false })

  const { data, error } = await useAPIFetch<PaginationResponse>(`/pipelines/${id}/leads?${queryFormatted}`)
  if (error.value) throw createError(error.value)
  return toRaw(data.value)
}

export const useGetTablePipelineLeads = async (query: any) => {
  const queryFormatted = stringify(query, { arrayFormat: 'indices', encode: false })

  const { data, error } = await useAPIFetch(`/pipelines/sales/leads?${queryFormatted}`)

  if (error.value) throw createError(error.value)
  return toRaw(data.value)
}

export const useGetArchivedLeads = async (query: any) => {
  const queryFormatted = stringify(query, { arrayFormat: 'indices', encode: false })

  const { data, error } = await useAPIFetch<PaginationResponse>(`/pipelines/archive/leads?${queryFormatted}`)
  if (error.value) throw createError(error.value)
  return toRaw(data.value)
}

export const useGetNextLead = async (id: number, leadId: number, filters: any, gridView: boolean) => {
  const queryFormatted = stringify(
    { ...filters, current_lead_id: leadId, is_kanban: gridView },
    { arrayFormat: 'comma', encode: false }
  )

  return await useAPIFetch<ApiResponse>(`pipelines/${id}/leads/next?${queryFormatted}`, {
    method: 'get',
  })
}

export const usePipelineSalesQuickFilters = async () =>
  await useAPIFetch(`/dictionary/pipeline_sales_leads_quick_filter_types`)

export const useArchiveQuickFilters = async () =>
  await useAPIFetch(`/dictionary/pipeline_archive_leads_quick_filter_types`)

export const exportLeadsTable = async (filters: any, headers: string[]) => {
  let queryFormatted = stringify({ ...filters })
  queryFormatted = `${queryFormatted}&columns=${headers.join(',')}&format=csv`
  return await new Promise((resolve, reject) => {
    useAPIFetch(`/pipelines/sales/leads/export?${queryFormatted}`, {
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        createError(response._data)
      },
    })
  })
}

export const exportLeadsArchiveTable = async (filters: any, headers: string[]) => {
  let queryFormatted = stringify({ ...filters })
  queryFormatted = `${queryFormatted}&columns=${headers.join(',')}&format=csv`
  return await new Promise((resolve, reject) => {
    useAPIFetch(`/pipelines/archive/leads/export?${queryFormatted}`, {
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        reject(createError(response._data))
      },
    })
  })
}
