<template>
  <li
    :tabindex="item.disabled || isHeading ? -1 : 0"
    class="relative flex select-none flex-row items-center justify-between gap-1 whitespace-normal outline-[1.5px] outline-success-100 hover:bg-black-03 focus-visible:outline"
    :class="[
      {
        'border-b border-solid border-black-10': item.divider,
        'text-black-60': item.disabled,
        '!min-h-10 cursor-default border-t border-solid border-black-10 bg-white first:border-none hover:bg-white':
          isHeading && !selectableHeading,
        'cursor-pointer': isHeading && selectableHeading,
        'cursor-pointer text-primary-120': modelValue.includes(item.value),
        'cursor-pointer active:bg-primary-05': !modelValue.includes(item.value) && !isHeading,
      },
      compact || isHeading ? 'min-h-10 px-3 py-2' : 'min-h-12 p-3',
    ]"
    @click.stop.prevent="select(item)"
    @keydown.enter="select(item)"
  >
    <h5 v-if="isHeading && !selectableHeading" :class="headingClasses" class="text-subhead-3 mt-1 text-primary-80">
      {{ item.text }}
    </h5>
    <UiInputCheckbox
      v-else
      :key="Number(isChecked)"
      :model-value="isChecked"
      :name="item.text"
      :disabled="item.disabled"
      :focusable="false"
      :indeterminate="isHeadingIndeterminate(item)"
    >
      <template #label>
        <label
          :for="item.text"
          class="flex cursor-pointer flex-row items-center gap-2"
          :class="[isHeading ? 'text-subhead-3 text-primary-80' : 'text-body-2']"
        >
          <UiAvatar v-if="avatar && !isHeading" :user="item" classes="w-5 min-w-5 h-5 text-subhead-3 !text-xs" />
          <div v-if="item.icon">
            <UiIcon
              :name="item.icon"
              class="rounded-md"
              :class="item.isTag ? `${useGetTagPropertiesByCode(item.code)?.class} !h-7 !w-7 p-1.5` : 'w-6'"
            />
          </div>
          <small class="[overflow-wrap:anywhere]">
            {{ item.text }}
          </small>
        </label>
      </template>
    </UiInputCheckbox>
  </li>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import type { InputItem } from '@/types'

const HEADER_VALUE = 'heading'

const emits = defineEmits(['input', 'select-dependents'])

type Props = {
  modelValue: any[]
  indeterminate?: boolean
  item: InputItem
  dependents?: any[]
  selectableHeading?: boolean
  compact?: boolean
  headingClasses?: HTMLAttributes['class']
  group?: boolean
  avatar?: boolean
}

const props = defineProps<Props>()

const isHeading = computed(() => props.item.value === HEADER_VALUE)
const isChecked = computed(() =>
  props.item.value === HEADER_VALUE
    ? props.dependents?.every((item) => props.modelValue.includes(item.value))
    : props.modelValue.includes(props.item.value)
)

const select = (item: InputItem) => {
  if (item.disabled) return
  if (item.value === HEADER_VALUE) {
    if (!props.selectableHeading) return
    emits('select-dependents', props.dependents)
  } else {
    emits('input', item.value)
  }
}

const isHeadingIndeterminate = (item: InputItem) => {
  if (props.selectableHeading && item.value !== HEADER_VALUE) return undefined
  return (
    props.dependents?.some((item) => props.modelValue.includes(item.value)) &&
    !props.dependents?.every((item) => props.modelValue.includes(item.value))
  )
}
</script>

<style scoped></style>
